
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom"
import Home from "./pages/Home.page";
import About from "./pages/About.page";
import HowItWorks from "./pages/HowItWorks.page";
import Models from "./pages/Models.page";
import Faq from "./pages/Faq.page";
import Contact from "./pages/Contact.page";
import Model from "./pages/Model.page";
import Technology from "./pages/Technology.page";
import PrivacyPolicy from "./pages/PrivacyPolicy.page";
import TermsOfService from "./pages/TermsOfService.page";
import VCard from "./pages/VCard.page";
import MediaCoverage from "./pages/MediaCoverage.page";
import Gallery from './pages/Gallery.page';
import Shop from './pages/Shop.page';
import Product from './pages/Product.page';
import Blog from './pages/Blog.page';
import Blogs from './pages/Blogs.page';
import Cart from './pages/Cart.page';
import Checkout from './pages/Checkout.page';
import PaymentSuccess from './pages/PaymentSuccess.page'
import NavbarComponent from "./components/Navbar.component";
import FooterComponent from "./components/Footer.component";
import PinCodeComponent from './components/PinCode.component';
import GaTracker from './components/GA.component';
import CookieConsentComponent from './components/CookieConsent.component';
import MetaPixelComponent from './components/MetaPixel.component';
import ls from 'localstorage-slim';
import useCart from './hooks/useCart';
import ErrorComponent from './components/Error.component';
import { getCurrentLang } from './utils/Local';
import RefundPolicy from './pages/RefundPolicy.page';
import ScheduleCallModalComponent from './components/ScheduleCallModal.component';


function App() {

  const { i18n } = useTranslation();
  const { t } = i18n;
  const { cart } = useCart()
  const [authenticated, setAuthenticated] = useState(false);
  const [totalFailedAuth, setTotalFailedAuth] = useState(0);
  const [pin, setPin] = useState('');
  const language = getCurrentLang();

  async function loadLeaflet() {

    const link = document.createElement('link');
    link.rel = 'stylesheet'
    link.href = `https://unpkg.com/leaflet@1.9.4/dist/leaflet.css`
    link.integrity = 'sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY='
    link.crossOrigin = ""
    document.head.appendChild(link);

    const script = document.createElement('script');
    script.src = `https://unpkg.com/leaflet@1.9.4/dist/leaflet.js`;
    script.integrity = 'sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo='
    script.async = true;
    script.crossOrigin = ""
    document.head.appendChild(script);
  }

  useEffect(() => {
    i18n.changeLanguage(language).then(r => { }).catch(e => { console.error(e) });
  }, [i18n, language])

  // Scroll to top
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Disable right-click
  useEffect(() => {
    function handleContextMenu(e: any) {
      e.preventDefault();
    }

    const rootElement = document.getElementById('root') as HTMLElement;
    rootElement.addEventListener('contextmenu', handleContextMenu);

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  // Cart
  useEffect(() => {
    ls.set('cart', cart, { ttl: 432000, encrypt: true });
  }, [cart])

  const handlePinComplete = (completedPin: string) => {

    if (completedPin === pin) {
      return;
    }

    setPin(completedPin)

    if (completedPin === process.env.REACT_APP_PIN_CODE) {
      setAuthenticated(true)
    } else {
      setTotalFailedAuth((prev) => prev + 1)
    }

  };

  const innerRoutes = (
    <Route>
      <Route index element={<Home />} />
      <Route path={`${t('_route.models')}/overview`} element={<Models />} />
      <Route path={`${t('_route.models')}/:model`} element={<Model />} />
      <Route path={`${t('_route.how-it-works')}`} element={<HowItWorks />} />
      <Route path={`${t('_route.the-technology')}`} element={<Technology />} />
      <Route path={`${t('_route.our-story')}`} element={<About />} />
      <Route path={`${t('_route.faq')}`} element={<Faq />} />
      <Route path={`${t('_route.contact-us')}`} element={<Contact />} />
      <Route path={`${t('_route.privacy-policy')}`} element={<PrivacyPolicy />} />
      <Route path={`${t('_route.refund-policy')}`} element={<RefundPolicy />} />
      <Route path={`${t('_route.terms-of-service')}`} element={<TermsOfService />} />
      <Route path={`${t('_route.vcard')}/:contact`} element={<VCard />} />
      <Route path={`${t('_route.media-coverage')}`} element={<MediaCoverage />} />
      <Route path={`${t('_route.gallery')}`} element={<Gallery />} />
      <Route path={`${t('_route.shop')}/:product`} element={<Product />} />
      <Route path={`${t('_route.blog')}/:blog`} element={<Blog />} />
      <Route path={`${t('_route.blog')}`} element={<Blogs />} />
      <Route path={`${t('_route.shop')}`} element={<Shop />} />
      <Route path={`${t('_route.cart')}`} element={<Cart />} />
      <Route path={`${t('_route.checkout')}`} element={<Checkout />} />
      <Route path={`${t('_route.success')}`} element={<PaymentSuccess />} />
      <Route path={`${t('_route.error')}`} element={<ErrorComponent />} />
    </Route>
  );

  // temp: plain layout | TODO: implement multiple layouts
  const displayDefaultLayout = (pathname.startsWith('/vcard')) === false

  GaTracker()
  loadLeaflet()

  return (
    <>

      {totalFailedAuth > 4 ?
        <>
          Error!
        </>
        :
        <>

          {(process.env.REACT_APP_ENV === 'production' || authenticated  || process.env.NODE_ENV === 'development') ?

            <>
              <MetaPixelComponent />
              <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>

                {displayDefaultLayout &&
                  <NavbarComponent />
                }
                <div style={{ flex: '2 0', position: 'relative' }}>
                  <Routes>
                    <Route path="/">
                      {innerRoutes}
                    </Route>

                    <Route path="fr/" >
                      {innerRoutes}
                    </Route>

                    <Route path="*" element={<Home />} />
                  </Routes>
                </div>

                {displayDefaultLayout &&
                  <>
                    <FooterComponent />
                    <CookieConsentComponent />
                    <ScheduleCallModalComponent />
                  </>
                }
              </div>
            </>

            :

            <>
              <PinCodeComponent onComplete={handlePinComplete} />
            </>
          }
        </>
      }


    </>
  );
}

export default App;