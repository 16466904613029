import { useState } from 'react'
import DocumentComponent from '../components/Document.component';
import { useParams, Navigate } from 'react-router-dom';
import i18n from "../i18n"
import models from "../data/models.json"
import HeroComponent from '../components/Hero.component';
import { Container, Row, Col, Image, Modal } from 'react-bootstrap';
import { formatter } from '../utils/Toolbox';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import ContainerDivider from '../components/ContainerDivider.component';
import StandardFeaturesComponent from '../components/StandardFeatures.component';
import { IImage } from '../interfaces/IImage';
import PremiumUpgradesComponent from '../components/PremiumUpgrades.component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBottleWater, faDollar, faRuler } from "@fortawesome/free-solid-svg-icons";
import { IGalleryImage } from '../interfaces/IGalleryImage';

export default function Model() {

    const { t } = i18n;
    const params = useParams();

    const modelKey = (params.model || '').toLowerCase();
    const model = models.find((item: any) => item.key.toLowerCase() === modelKey);
    const [showGallery, setShowGallery] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    if (model === undefined) {
        return (<Navigate to="/models" />)
    }

    const openGallery = (index: number) => {
        setCurrentIndex(index);
        setShowGallery(true);
    };

    const images: IGalleryImage[] = model.images.map((item: IImage, index: number) => {
        return {
            original: item.src,
            thumbnail: item.src,
            description: item.alt,
            alt: item.alt,
        }
    })


    const renderThumbnails = (start: number = 0, end: number = 0) => {

        const items = model.images.slice(start, end);

        if (items.length === 3) {

            if (modelKey === 'pakalps' && start === 3) {
                return (
                    <Row>
                        {items.map((item: IImage, index: number) => {
                            return (
                                <Col xs={index === 0 ? '12' : '6'} className='mb-3'>
                                    <Image src={item.src} role='button' className='mw-100' rounded onClick={() => openGallery((start + index))} alt={item.alt} />
                                </Col>
                            )
                        })}
                    </Row>
                )

            }

            let orderClass = modelKey === 'pakstockholm' ? 'order-lg-2' : 'order-lg-1'
            if (start === 0) {
                orderClass = modelKey === 'pakstockholm' ? 'order-lg-1' : 'order-lg-2'
            }

            return (
                <Row>

                    {/* render first image in the batch */}
                    <Col xs={12} lg={8} className={`text-center mb-4 ${orderClass}`}>
                        <Image src={items[0].src} role='button' className='mw-100' rounded onClick={() => openGallery((start))} alt={items[0].alt} />
                    </Col>

                    <Col xs={12} lg={4} className='text-center order-lg-1'>

                        <Row>
                            {items.map((item: IImage, index: number) => {

                                // skip the first image
                                if (index === 0) {
                                    return (<span key={index}></span>)
                                }

                                return (
                                    <Col key={index} xs={6} lg={12} className='mb-3'>
                                        <Image src={item.src} role='button' className='mw-100' rounded onClick={() => openGallery((start + index))} alt={item.alt} />
                                    </Col>
                                )

                            })}
                        </Row>
                    </Col>
                </Row>
            )
        }


        return (
            <Row>
                {items.map((item: IImage, index: number) => {
                    return (
                        <Col key={index} xs={6} className='mb-3'>
                            <Image src={item.src} role='button' className='mw-100' rounded onClick={() => openGallery((start + index))} alt={item.alt} />
                        </Col>
                    )
                })}
            </Row>
        )
    }

    return (<>

        <DocumentComponent title={model.name} og={model.og} />

        <HeroComponent title={model.name} />

        <Modal show={showGallery} fullscreen={true} onHide={() => setShowGallery(false)} data-bs-theme="dark" className='modalGallery'>
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                <Gallery
                    items={images}
                    startIndex={currentIndex}
                    showThumbnails={false}
                />
            </Modal.Body>
        </Modal>

        <Container>

            <Row className='text-center mb-4 modelSummaryContainer'>

                <Col xs={6} md={4} className='order-2 order-md-1'>
                    <FontAwesomeIcon icon={faDollar} color="var(--info-color)" size='2x' />
                    <p className="modelSubHeader justify-content-center">
                        {t('starting_from_price', { price: formatter.format(model.variants[0].price) })}
                    </p>
                </Col>

                <Col xs={12} md={4} className='order-1 order-md-2'>
                    <FontAwesomeIcon icon={faBottleWater} color="var(--info-color)" size='2x' />
                    <p className="modelSubHeader justify-content-center">
                        {t('made_from_x_recycled_water_bottles', { x: model.variants[0].bottles ? model.variants[0].bottles.toLocaleString() : 0 })}
                    </p>
                </Col>

                <Col xs={6} md={4} className='order-3 order-md-3'>
                    <FontAwesomeIcon icon={faRuler} color="var(--info-color)" size='2x' />
                    <p className="modelSubHeader justify-content-center">
                        {t('size')} {model.variants[0].size}
                    </p>
                </Col>
            </Row>

            <Row>
                {
                    model.descriptions.map((description: string, index: number) => (
                        <p key={index}>{t(description)}</p>
                    ))
                }
            </Row>


            {modelKey === 'pakalps' ?

                <>
                    {renderThumbnails(0, 3)}
                    {renderThumbnails(3, 6)}
                </>

                :

                <>
                    {renderThumbnails(0, 3)}
                    {renderThumbnails(3, 5)}
                    {renderThumbnails(5, 8)}
                </>

            }

        </Container>


        <Container >

            <Row>

                {
                    model.variants.map((variant: any, index: number) => {


                        // Add to the gallery
                        images.push({
                            original: variant.img,
                            thumbnail: variant.img,
                            description: variant.name,
                            alt: variant.name,
                        })

                        let span = 4;

                        if ([1, 2].includes(model.variants.length)) {
                            span = 12
                        }

                        return (

                            <Col key={index} md={span} className='text-center mb-4'>
                                <Row>

                                    <Col xs={12} className="order-2 order-md-1">
                                        <div className='rounded' style={{ backgroundColor: '#CCCCCC' }} onClick={() => openGallery((images.length - model.variants.length + index))}>
                                            <Image src={variant.img} role='button' className='mw-100' rounded alt={variant.name} />
                                        </div>
                                    </Col>

                                    {model.variants.length > 1 &&

                                        <Col xs={12} className="mt-4 order-1 order-md-2">
                                            <h2 className='itemHeader' style={{ color: 'var(--bg-dark-color)' }}>{t(variant.name)}</h2>
                                            <p className='mb-1'>{t('total_living_space')}: {variant.total_living_space} {t('sq_ft')}</p>
                                            <p>{t('footprint')}: {variant.footprint} {t('sq_ft')}</p>
                                        </Col>

                                    }
                                </Row>
                            </Col>
                        )
                    })
                }

            </Row>

        </Container>

        <ContainerDivider title={t('standard_features')} />

        <StandardFeaturesComponent standardFeatures={model.standard_features} modelName={model.variants[0].name} />

        <ContainerDivider title={t('premium_upgrades')} />

        <PremiumUpgradesComponent />

    </>)
}