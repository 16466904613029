export const termsEn = `
<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c2 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c3 {
            padding-top: 16pt;
            padding-bottom: 4pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c10 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 20pt;
            font-family: "Arial";
            font-style: normal
        }

        .c0 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c6 {
            color: #434343;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-family: "Arial";
            font-style: normal
        }

        .c8 {
            padding-top: 20pt;
            padding-bottom: 6pt;
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c5 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c9 {
            text-decoration-skip-ink: none;
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            text-decoration: underline
        }

        .c1 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c7 {
            color: inherit;
            text-decoration: inherit
        }

        .c4 {
            background-color: #ffff00
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c1 doc-content">
    <h1 class="c8" id="h.utr2ldg2fsnf"><span class="c10">Terms of Service </span></h1>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">Last updated: 2024-06-20</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.r2vfffk6y27s"><span class="c6">OVERVIEW </span></h3>
    <p class="c5"><span class="c2">Pakville.ca is operated by PakInnovate Corp. (&ldquo;PakInnovate&rdquo;), primarily serving users in Canada and the United States. Throughout the site, the terms &ldquo;we&rdquo;, &ldquo;us&rdquo; and
            &ldquo;our&rdquo; refer to PakInnovate. PakInnovate offers this website, including all information, tools and Services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and
            notices stated here.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">By visiting our site and/ or purchasing something from us, you engage in our &ldquo;Service&rdquo; and agree to be bound by the following terms and conditions (&ldquo;Terms of Service&rdquo;, &ldquo;Terms&rdquo;),
            including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers,
            merchants, and/ or contributors of content.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms
            and conditions of this agreement, then you may not access the website or use any Services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">Any new features or tools which are added to the current website shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve
            the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the
            website following the posting of any changes constitutes acceptance of those changes.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">Our website is hosted on Amazon Web Services (AWS).</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.7wson6ammn78"><span class="c6">SECTION 1 - TERMS</span></h3>
    <p class="c5"><span class="c2">By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and
            you have given us your consent to allow any of your minor dependents to use this site.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">You must not transmit any worms or viruses or any code of a destructive nature. A breach or violation of any of the Terms will result in an immediate termination of your Services.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.wxwvwwb1qb0c"><span class="c6">SECTION 2 - GENERAL CONDITIONS</span></h3>
    <p class="c5"><span class="c2">We reserve the right to refuse Service to anyone for any reason at any time. </span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is managed securely by Stripe and is always encrypted during transfer across networks.</span>
    </p>
    <p class="c0"><span class="c2 c4"></span></p>
    <p class="c5"><span class="c2">You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the Service is provided,
            without express written permission by us.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.7gnci7bxw9v"><span class="c6">SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</span></h3>
    <p class="c5"><span class="c2">We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the
            sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any
            time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.fvxsfz2psgrj"><span class="c6">SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</span></h3>
    <p class="c5"><span class="c2">Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable
            to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.iy8m2tvjvfr9"><span class="c6">SECTION 5 - PRODUCTS OR SERVICES </span></h3>
    <p class="c0"><span class="c2 c4"></span></p>
    <p class="c5"><span class="c2">Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Refund Policy.</span></p>
    <p class="c0"><span class="c2 c4"></span></p>
    <p class="c5"><span class="c2">We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor&#39;s display of any color will be
            accurate.</span></p>
    <p class="c0"><span class="c2 c4"></span></p>
    <p class="c5"><span class="c2">We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right
            to limit the quantities of any products or Services that we offer. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any
            product at any time. Any offer for any product or Service made on this site is void where prohibited.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span>We do not warrant that the quality of any products, Services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.</span></p>
    <h3 class="c3" id="h.iy8m2tvjvfr9"><span class="c6">SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION </span></h3>
    <p class="c5"><span class="c2">We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
    </span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
    </span></p>
    <p class="c0"><span class="c2"></span></p>

    <h3 class="c3" id="h.9pz5pu9jdg0t"><span class="c6">SECTION 7 - OPTIONAL TOOLS</span></h3>
    <p class="c5"><span class="c2">We may provide you with access to third-party tools over which we neither monitor nor have any control nor input. You acknowledge and agree that we provide access to such tools &rdquo;as is&rdquo; and &ldquo;as
            available&rdquo; without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">Any use by you of the optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the
            relevant third-party provider(s).</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">We may also, in the future, offer new Services and/or features through the website (including the release of new tools and resources). Such new features and/or Services shall also be subject to these Terms of
            Service.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.94x89trtqo8s"><span class="c6">SECTION 8 - THIRD-PARTY LINKS</span></h3>
    <p class="c5"><span class="c2">Certain content, products and Services available via our Service may include materials from third-parties.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not
            have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or Services of third-parties.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">We are not liable for any harm or damages related to the purchase or use of goods, Services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully
            the third-party&#39;s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</span>
    </p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.77cwqqjdb4e3"><span class="c6">SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</span></h3>
    <p class="c5"><span class="c2">If, at our request, you send certain specific submissions (for example contest entries) or without a request from us, you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by
            email, by postal mail, or otherwise (collectively, &#39;comments&#39;), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to
            us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion to be unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise
            objectionable or violates any party&rsquo;s intellectual property or these Terms of Service.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not
            contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e&#8209;mail
            address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume
            no liability for any comments posted by you or any third-party.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.xg2l6scujxlj"><span class="c6">SECTION 10 - PERSONAL INFORMATION</span></h3>
    <p class="c5"><span>Your submission of personal information through the website is governed by our Privacy Policy, which can be viewed </span><span class="c9"><a class="c7" href="/privacy-policy">here</a></span><span>.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.vz6hpv2j89nn"><span class="c6">SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</span></h3>
    <p class="c5"><span class="c2">Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product
            shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is
            inaccurate at any time without prior notice (including after you have submitted your order).</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or
            refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.5hgxbfwgi1sn"><span class="c6">SECTION 12 - PROHIBITED USES</span></h3>
    <p class="c5"><span class="c2">In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any
            unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others;
            (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading
            information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the
            Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of
            the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.5xpokcoy412m"><span class="c6">SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</span></h3>
    <p class="c5"><span class="c2">We do not guarantee, represent or warrant that your use of our Service will be uninterrupted, timely, secure or error-free.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">You agree that from time to time we may remove the Service for indefinite periods of time or cancel the Service at any time, without notice to you.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">You expressly agree that your use of, or inability to use, the Service is at your sole risk. The Service and all products and Services delivered to you through the Service are (except as expressly stated by us)
            provided &#39;as is&#39; and &#39;as available&#39; for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable
            quality, fitness for a particular purpose, durability, title, and non-infringement.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">In no case shall PakInnovate Corp, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, Service providers or licensors be liable for any injury, loss, claim, or any direct,
            indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract,
            tort (including negligence), strict liability or otherwise, arising from your use of any of the Service or any products procured using the Service, or for any other claim related in any way to your use of the Service or any product,
            including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Service or any content (or product) posted, transmitted, or otherwise made available via the
            Service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be
            limited to the maximum extent permitted by law.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.fuc8c4tik1ux"><span class="c6">SECTION 14 - INDEMNIFICATION</span></h3>
    <p class="c5"><span class="c2">You agree to indemnify, defend and hold harmless PakInnovate and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, Service providers, subcontractors, suppliers,
            interns and employees, harmless from any claim or demand, including reasonable attorneys&rsquo; fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference,
            or your violation of any law or the rights of a third-party.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.5gw5q1ek5gsy"><span class="c6">SECTION 15 - SEVERABILITY</span></h3>
    <p class="c5"><span class="c2">In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and
            the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.q63rfno5b806"><span class="c6">SECTION 16 - TERMINATION</span></h3>
    <p class="c5"><span class="c2">The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you
            cease using our site.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will
            remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.27wtx6uq13c0"><span class="c6">SECTION 17 - ENTIRE AGREEMENT</span></h3>
    <p class="c5"><span class="c2">The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">These Terms of Service and any policies or operating rules posted by us on this site or in respect to the Service constitutes the entire agreement and understanding between you and us and governs your use of the
            Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.qilqlwbhlgrm"><span class="c6">SECTION 18 - GOVERNING LAW</span></h3>
    <p class="c5"><span class="c2">These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Quebec.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.uxu8obm07w1b"><span class="c6">SECTION 19 - CHANGES TO TERMS OF SERVICE</span></h3>
    <p class="c5"><span class="c2">You can review the most current version of the Terms of Service at any time at this page. We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting
            updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service
            constitutes acceptance of those changes.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <h3 class="c3" id="h.xuh9zeu7ozx2"><span class="c6">SECTION 20 - CONTACT INFORMATION</span></h3>
    <p class="c5"><span>Questions about the Terms of Service should be sent to us at </span><span class="c9"><a class="c7" href="mailto:info@pakville.ca">info@pakville.ca</a></span><span class="c2">.</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">Our contact information is posted below:</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">&#x1f3e2; PakVille operated by PakInnovate Corp.</span></p>
    <p class="c5"><span class="c2">&#x1f4e7; info@pakville.ca</span></p>
    <p class="c5"><span class="c2">&#x1f4cd; 618 Av. Meloche, Dorval, Quebec H9P 2P4, Canada</span></p>
    <p class="c5"><span class="c2">&#x1f4de; +1 (514) 885-1361</span></p>
    <p class="c5"><span class="c2">&#x1f522; BN number: 729384610 RC0001</span></p>
    <p class="c5"><span class="c2">&#x1f194; NEQ number: 1179148227</span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c0"><span class="c2"></span></p>

    <h3 class="c3" id="h.uxu8obm07w1b"><span class="c6">SECTION 21 - ADDITIONAL TERMS AND CONDITIONS</span></h3>

    <p class="c5"><span class="c2"><strong>Material Shortage.</strong> Due to a shortage of construction material for an undetermined duration in North America and delays in suppliers' production of construction material, we reserve the right to adjust the schedule if we are unable to procure the material required for an order as per the agreed schedule. Should the above mentioned shortage affect the procurement of material for this order, we shall not be held responsible for any damages or delay arising from an adjustment to the schedule.</span></p>
    <p class="c0"><span class="c2"></span></p>

    <p class="c5"><span class="c2"><strong>Warranties.</strong> All products manufactured by us are warranted for a period of one (1) year following the date of their delivery to be free from defects in workmanship and material. If any products fall under the terms of this guarantee, PakInnovate shall, without cost to you, at PakInnovate's option, either: (i) repair any such defective materials; or (ii) furnish replacement products. Damages to the products caused by you or any person that is not under PakInnovate's control, an event of force majeure, a defective installation, a use other than that for which product is designed, an abnormal or abusive use or other causes unrelated to PakInnovate’s activities are specifically excluded from the coverage of this warranty. 
    </span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">The foregoing warranties are exclusive and are given and accepted in lieu of any and all other warranties, express or implied, to the extent permitted by the applicable laws.  Your remedies  for any breach of warranty shall be limited to those provided herein.
    </span></p>
    <p class="c0"><span class="c2"></span></p>

    <p class="c5"><span class="c2"><strong>Limitation of liability.</strong> PakInnovate's liability for a claim or a loss or damage arising out of a defective product  shall be limited to the costs of replacing or correcting, at PakInnovate's option, the defective product and shall in no event exceed the purchase price paid for the defective product . In no event shall PakInnovate be liable for incidental, consequential or indirect damages, including any loss of profit or loss of revenue, or for liquidated or punitive damages.
    </span></p>
    <p class="c0"><span class="c2"></span></p>

    <p class="c5"><span class="c2"><strong>Order processing time.</strong> After an order is placed, we will contact you within two working days to confirm the fabrication schedule and shipping date if applicable.
    </span></p>
    <p class="c0"><span class="c2"></span></p>

    <p class="c5"><span class="c2"><strong>Delivery, Title and Risk of Loss.</strong> Title to and risk of loss for all products shipped by PakInnovate vest in Buyer upon delivery to Buyer. Delivery is deemed to have occurred when the product has been unloaded from the delivery truck at the agreed delivery site or, should access to the agreed site be hampered or deemed impractical by the truck driver, at any alternate site agreed by you and PakInnovate and reasonably accessible by the delivery truck.
    </span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">The shipping price of the products includes one (1) hour for waiting and unloading at the delivery site. PakInnovate may, at its option, invoice you $120.00 per hour for unloading and waiting time in excess of the initial allotted one (1) hour.
    </span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">PakInnovate will notify you of the date and time of delivery at the latest the day prior to delivery. PakInnovate will accept your claims for shortages on the shipment provided the shortages are noted on the shipping papers at the time of delivery.
    </span></p>
    <p class="c0"><span class="c2"></span></p>

    <p class="c5"><span class="c2"><strong>Force Majeure.</strong> PakInnovate shall not be responsible for delays in delivery and in performance, in whole or part, from any cause beyond PakInnovate's reasonable control or from force majeure (superior force), including, but not limited to: COVID-19 Pandemic, labour conflicts (whether legal or illegal); shortage of carriers, materials or supplies. In the event of delays, the time of completion shall be extended accordingly.
    </span></p>
    <p class="c0"><span class="c2"></span></p>

    <p class="c5"><span class="c2"><strong>Claims and Indemnification.</strong> Claims for shortages, goods damaged in transit and defective products must be reported to PakInnovate, in writing, within 48 hours from delivery of the item. 
    </span></p>
    <p class="c0"><span class="c2"></span></p>
    <p class="c5"><span class="c2">You agree to indemnify and hold PakInnovate harmless from all claims, demands, and costs, including reasonable attorney fees, made by third parties against PakInnovate relating to your use or installation of products, and to pay any costs and expenses incurred by PakInnovate to enforce your obligations, including, without limitation, the costs of collection for any unpaid amounts owed to PakInnovate by you.
    </span></p>
    <p class="c0"><span class="c2"></span></p>

</body>

</html>`